import type { ReactNode } from 'react'
import { theme } from '../../../styles'

export interface IBadge {
  children: ReactNode
}

export function Badge({ children }: IBadge): JSX.Element {
  return (
    <>
      <div className="Badge flex justify-center items-center">{children}</div>

      <style jsx>{`
        .Badge {
          border-radius: 24px;
          background-color: ${theme.cssVars.yellowGolden};
          color: ${theme.cssVars.deepBlue};
          padding: 7px 12px 3px;
          font-weight: 800;
          line-height: 15px;
          font-size: 15px;
          vertical-align: middle;
          text-transform: uppercase;
        }
      `}</style>
    </>
  )
}
