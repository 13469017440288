export interface IModal {
  children: React.ReactNode
  backgroundColor?: string
  isOpen: boolean
}

export function Modal({ children, isOpen }: IModal): JSX.Element {
  if (!isOpen) {
    return null
  }

  return (
    <>
      <div className="Modal flex justify-center items-center">
        <div className="Modal__Backdrop" />
        <div className="Modal__Content">{children}</div>
      </div>

      <style jsx>{`
        .Modal {
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100vw;
          height: 100vh;

          padding: 0 30px;

          z-index: 15;
        }

        .Modal__Backdrop {
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100vw;
          height: 100vh;
          background-color: var(--color-deepBlue);
          opacity: 0.7;
        }

        .Modal__Content {
          z-index: 1;
          padding: 40px 30px;
          border-radius: 10px;
          background-color: var(--color-white);
          max-width: 500px;
          width: 100%;
        }
      `}</style>
    </>
  )
}
