export function Mail({
  color = 'var(--color-deepBlue)',
  secondary = 'var(--color-white)',
}: {
  color?: string
  secondary?: string
}): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      data-testid="mail"
    >
      <path d="M3.5 8L12 13L20.5 8" stroke={color} strokeWidth="1.67" strokeLinejoin="bevel" />
      <g clipPath="url(#clip0_34463_117738)">
        <path
          d="M3.5 8L12 13L20.5 8"
          stroke={secondary}
          strokeWidth="1.67"
          strokeLinejoin="bevel"
        />
      </g>
      <rect x="3.335" y="5.835" width="17.33" height="12.33" stroke={color} strokeWidth="1.67" />
      <defs>
        <clipPath id="clip0_34463_117738">
          <rect width="5" height="9" fill={color} transform="translate(3.5 7)" />
        </clipPath>
      </defs>
    </svg>
  )
}
