import { Button } from '../../atoms/Button'
import { Modal } from '../../atoms/Modal'
import { SVGIcon } from '../../atoms/SVGIcon'

export interface IConfirmModal {
  isOpen: boolean
  title: string
  buttonLabel: string
  onClose: () => void
}

export function ConfirmModal({ isOpen, title, buttonLabel, onClose }: IConfirmModal): JSX.Element {
  return (
    <>
      <Modal isOpen={isOpen}>
        <div className="ConfirmModal flex flex-column items-center">
          <h2
            className="ConfirmModal__Title"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <SVGIcon name="check" size="100px" />
          <Button
            backgroundColor="var(--color-deepBlue)"
            textColor="var(--color-white)"
            onClick={onClose}
          >
            {buttonLabel}
          </Button>
        </div>
      </Modal>

      <style jsx>{`
        .ConfirmModal__Title {
          color: var(--color-deepBlue);
          font-weight: 900;
          font-size: 22px;
          line-height: 136%;
          text-align: center;
        }
      `}</style>
    </>
  )
}
