import { Button } from '../../atoms/Button'
import { Modal } from '../../atoms/Modal'
import { SVGIcon } from '../../atoms/SVGIcon'

export interface IValidationNewsletterModal {
  isOpen: boolean
  title: string
  accept: string
  refuse: string
  onAccept: () => void
  onRefuse: () => void
}

export function ValidationNewsletterModal({
  isOpen,
  title,
  accept,
  refuse,
  onAccept,
  onRefuse,
}: IValidationNewsletterModal): JSX.Element {
  return (
    <>
      <Modal isOpen={isOpen}>
        <div className="ValidationNewsletterModal flex flex-column items-center">
          <h2
            className="ValidationNewsletterModal__Title"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <SVGIcon name="alert-circle" size="100px" />
          <Button
            backgroundColor="var(--color-deepBlue)"
            textColor="var(--color-white)"
            onClick={onAccept}
          >
            {accept}
          </Button>
          <Button
            backgroundColor="var(--color-white)"
            textColor="var(--color-deepBlue)"
            onClick={onRefuse}
          >
            {refuse}
          </Button>
        </div>
      </Modal>

      <style jsx>{`
        .ValidationNewsletterModal__Title,
        .ValidationNewsletterModal :global(> svg) {
          margin-bottom: 30px;
        }

        .ValidationNewsletterModal__Title {
          color: var(--color-deepBlue);
          font-weight: 900;
          font-size: 22px;
          line-height: 136%;
          text-align: center;
        }
      `}</style>
    </>
  )
}
