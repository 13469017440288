import type { IPicture } from '../../../types/pictures'
import { PictureElementList } from '../PictureElementList'
import { theme } from '../../../styles'

export interface IBackgroundImage {
  pictures: IPicture
  fallbackColor?: string
}

export function BackgroundImage({ pictures, fallbackColor }: IBackgroundImage): JSX.Element {
  return (
    <>
      <div className="BackgroundImage">
        <div className="BackgroundImage__Filter" />
        {pictures ? <PictureElementList {...pictures} withoutFallback /> : null}
      </div>

      <style jsx>{`
        .BackroundImage {
          background: ${fallbackColor || theme.cssVars.white};
        }
      `}</style>

      <style jsx>{`
        .BackgroundImage,
        .BackgroundImage__Filter {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          overflow: hidden;
        }

        .BackgroundImage__Filter {
          display: none;
        }

        .BackgroundImage :global(img),
        .BackgroundImage :global(amp-img) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -2;
        }
      `}</style>
    </>
  )
}
