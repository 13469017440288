export function MailOpen({
  color = 'var(--color-deepBlue)',
  secondary = 'var(--color-white)',
}: {
  color?: string
  secondary?: string
}): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-testid="mail-open"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clipPath="url(#clip0_35548_153743)">
        <path
          d="M3 9.37891L12.5 3.37891L22 9.37891"
          stroke={color}
          strokeWidth="2"
          strokeLinejoin="bevel"
        />
      </g>
      <g clipPath="url(#clip1_35548_153743)">
        <path
          d="M3 9.37891L12.5 15.3789L22 9.37891"
          stroke={color}
          strokeWidth="2"
          strokeLinejoin="bevel"
        />
        <g clipPath="url(#clip2_35548_153743)">
          <path
            d="M3 9.37891L12.5 15.3789L22 9.37891"
            stroke={secondary}
            strokeWidth="2"
            strokeLinejoin="bevel"
          />
        </g>
      </g>
      <mask xmlns="http://www.w3.org/2000/svg" id="path-4-inside-1_35548_153743" fill="white">
        <path d="M3 8.37891H22V22.3789H3V8.37891Z" />
      </mask>
      <path
        d="M22 22.3789V24.3789H24V22.3789H22ZM3 22.3789H1V24.3789H3V22.3789ZM20 8.37891V22.3789H24V8.37891H20ZM22 20.3789H3V24.3789H22V20.3789ZM5 22.3789V8.37891H1V22.3789H5Z"
        fill={color}
        mask="url(#path-4-inside-1_35548_153743)"
      />
      <defs>
        <clipPath id="clip0_35548_153743">
          <rect width="19" height="7" fill="white" transform="translate(3 2.37891)" />
        </clipPath>
        <clipPath id="clip1_35548_153743">
          <rect width="19" height="7" fill="white" transform="matrix(1 0 0 -1 3 16.3789)" />
        </clipPath>
        <clipPath id="clip2_35548_153743">
          <rect width="7" height="9" fill="white" transform="matrix(1 0 0 -1 3 18.3789)" />
        </clipPath>
      </defs>
    </svg>
  )
}
